import WebPage from "./WebPage/WebPage";
import AboutMe from "./AboutMe";
import Projects from "./Projects";
import Footer from "./Footer";

import NavigationBar from "./NavigationBar/NavigationBar";
import MarisaVukas_Resume from "./MarisaVukas_Resume.pdf";
import ProfilePic from "./ProfilePic.jpg";

export {
  WebPage,
  Projects,
  AboutMe,
  NavigationBar,
  Footer,
  ProfilePic,
  MarisaVukas_Resume,
};
